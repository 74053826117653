var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('b-row',[_c('b-col',{attrs:{"lg":"12"}},[_c('iq-card',{scopedSlots:_vm._u([{key:"headerTitle",fn:function(){return [_c('h4',{staticClass:"card-title"},[_vm._v("Personal Information")])]},proxy:true},{key:"body",fn:function(){return [_c('b-form-group',{staticClass:"row align-items-center"},[_c('b-col',{attrs:{"md":"12"}})],1),_c('b-row',{attrs:{"align-v":"center"}},[_c('b-form-group',{staticClass:"col-sm-12 col-md-6",attrs:{"label":"Full Name","label-for":"name"}},[_c('ValidationProvider',{attrs:{"name":"First name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:(errors.length > 0 ? ' is-invalid' : ''),attrs:{"type":"text","placeholder":"Full Name"},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-sm-12 col-md-6",attrs:{"label":"Email","label-for":"email"}},[_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:(errors.length > 0 ? ' is-invalid' : ''),attrs:{"readonly":"","type":"text","placeholder":"Enter your email"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-sm-12 col-md-6",attrs:{"label":"Number phone","label-for":"phone"}},[_c('ValidationProvider',{attrs:{"name":"phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:(errors.length > 0 ? ' is-invalid' : ''),attrs:{"type":"number","placeholder":"Enter your number phone"},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-sm-12 col-md-6",attrs:{"label":"Company name","label-for":"company name"}},[_c('ValidationProvider',{attrs:{"name":"companyname","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:(errors.length > 0 ? ' is-invalid' : ''),attrs:{"type":"text","placeholder":"Name of your company"},model:{value:(_vm.user.company_name),callback:function ($$v) {_vm.$set(_vm.user, "company_name", $$v)},expression:"user.company_name"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-sm-12 col-md-6",attrs:{"label":"Website","label-for":"website"}},[_c('ValidationProvider',{attrs:{"name":"website","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:(errors.length > 0 ? ' is-invalid' : ''),attrs:{"type":"text","placeholder":"Enter the link of website"},model:{value:(_vm.user.website),callback:function ($$v) {_vm.$set(_vm.user, "website", $$v)},expression:"user.website"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-sm-6",attrs:{"label-for":"exampleFormControlSelect4","label":"State"}},[_c('ValidationProvider',{attrs:{"name":"state","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{class:(errors.length > 0 ? ' is-invalid' : ''),attrs:{"plain":"","options":_vm.state,"id":"exampleFormControlSelect4"},model:{value:(_vm.user.status),callback:function ($$v) {_vm.$set(_vm.user, "status", $$v)},expression:"user.status"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)],1),_c('b-button',{staticClass:"mr-2",attrs:{"type":"submit","variant":"primary"}},[_vm._v("Submit")]),_c('router-link',{attrs:{"to":{name:'superadmin.dashboard'}}},[_c('b-button',{staticClass:"iq-bg-danger",attrs:{"type":"reset","variant":"none"}},[_vm._v("Cancel")])],1)]},proxy:true}],null,true)})],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }