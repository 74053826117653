<template>
  <b-container fluid>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <b-row>
          <b-col lg="12">
            <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">Personal Information</h4>
            </template>
            <template v-slot:body>
                <b-form-group class="row align-items-center">
                    <b-col md="12">
                    </b-col>
                </b-form-group>
                <b-row align-v="center">
                    <b-form-group class="col-sm-12 col-md-6" label="Full Name" label-for="name">
                        <ValidationProvider name="First name" rules="required" v-slot="{ errors }">
                            <b-form-input v-model="user.name" type="text" placeholder="Full Name" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                            <div class="invalid-feedback">
                            <span>{{ errors[0] }}</span>
                            </div>
                        </ValidationProvider>
                    </b-form-group>
                    <b-form-group class="col-sm-12 col-md-6" label="Email" label-for="email">
                        <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                            <b-form-input readonly v-model="user.email" type="text" placeholder="Enter your email" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                            <div class="invalid-feedback">
                            <span>{{ errors[0] }}</span>
                            </div>
                        </ValidationProvider>
                    </b-form-group>
                    <b-form-group class="col-sm-12 col-md-6" label="Number phone" label-for="phone">
                        <ValidationProvider name="phone" rules="required" v-slot="{ errors }">
                            <b-form-input v-model="user.phone" type="number" placeholder="Enter your number phone" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                            <div class="invalid-feedback">
                            <span>{{ errors[0] }}</span>
                            </div>
                        </ValidationProvider>
                    </b-form-group>
                    <b-form-group class="col-sm-12 col-md-6" label="Company name" label-for="company name">
                        <ValidationProvider name="companyname" rules="" v-slot="{ errors }">
                            <b-form-input v-model="user.company_name" type="text" placeholder="Name of your company" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                            <div class="invalid-feedback">
                            <span>{{ errors[0] }}</span>
                            </div>
                        </ValidationProvider>
                    </b-form-group>
                    <b-form-group class="col-sm-12 col-md-6" label="Website" label-for="website">
                        <ValidationProvider name="website" rules="" v-slot="{ errors }">
                            <b-form-input v-model="user.website" type="text" placeholder="Enter the link of website" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                            <div class="invalid-feedback">
                            <span>{{ errors[0] }}</span>
                            </div>
                        </ValidationProvider>
                    </b-form-group>
                    <b-form-group class="col-sm-6" label-for="exampleFormControlSelect4" label="State">
                        <ValidationProvider name="state" rules="required" v-slot="{ errors }">
                            <b-form-select plain v-model="user.status" :options="state" id="exampleFormControlSelect4" :class="(errors.length > 0 ? ' is-invalid' : '')">
                            </b-form-select>
                            <div class="invalid-feedback">
                            <span>{{ errors[0] }}</span>
                            </div>
                        </ValidationProvider>
                    </b-form-group>
                </b-row>
                <b-button type="submit" variant="primary" class="mr-2">Submit</b-button>
                <router-link :to="{name:'superadmin.dashboard'}">
                    <b-button type="reset" variant="none" class="iq-bg-danger">Cancel</b-button>
                </router-link>
            </template>
            </iq-card>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>
  </b-container>
</template>
<script>
import { core } from '../../config/pluginInit'
import { db } from '../../config/firebase'

export default {
  name: 'ProfileEdit',
  mounted () {
    core.index()
  },
  firestore () {
    return {
      user: db.collection('administrators/').doc(this.$route.params.idDoc)
    }
  },
  data () {
    return {
      user: {
        uid: '',
        name: '',
        email: '',
        company_name: '',
        profile_image: '',
        phone: '',
        website: '',
        status: true
      },
      state: [
        { value: true, text: 'Active' },
        { value: false, text: 'Inactive' }
      ]
    }
  },
  methods: {
    onSubmit () {
      // TODO: Desabilitar el correo
      db.collection('administrators').doc(this.$route.params.idDoc).set({
        uid: this.user.uid,
        name: this.user.name,
        email: this.user.email,
        company_name: this.user.company_name || '',
        profile_image: this.user.profile_image || '',
        phone: this.user.phone,
        website: this.user.website || '',
        status: this.user.status
      })
        .then(() => {
          core.showSnackbar('success', 'User has been updated successfully.')
          this.$router.replace('/sv-admin-clase-alpha/super-admin/dashboard')
        })
        .catch((error) => {
          core.showSnackbar('error', 'An error has ocurred.' + error.message)
        })
    }
  }
}
</script>
